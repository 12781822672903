<template>
  <div class="jackpot-border jackpot">
    <div class="jacpot-content-border">
      <!-- 边框上的原点 -->
      <div class="dots-container-bottom">
        <div class="dot" v-for="i in 30" :key="i"></div>
      </div>
      <div class="dots-container-top">
        <div class="dot" v-for="i in 30" :key="i"></div>
      </div>
      <div class="dots-container-right">
        <div class="dot" v-for="i in 3" :key="i"></div>
      </div>
      <div class="dots-container-left">
        <div class="dot" v-for="i in 3" :key="i"></div>
      </div>
      <!-- 标题 -->
      <div class="jackpot-title">
        <img src="@/assets/images/jackpot-stars.png" class="stars-icon">
        <img src="@/assets/images/jackpot-cp.png" class="cp-icon">
        <span :data-text="$t('JACKPOT TITLE')">{{ $t("JACKPOT TITLE") }}</span>
      </div>
      <div class="content">
        <span>{{ $helper.get("rule").currency.symbol }}</span>
        <div v-for="item, key in numFormat(number, false, 2)" :key="key" class="num-item">
          <div class="num-item-content" v-if="Number(item) >= 0" :style="`transform: translateY(-${item}0%);`">
            <span v-for="item, index in 10" :key="index">{{ index }}</span>
          </div>
          <div class="num-item-content" v-else>{{ item }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nums: "0123456789",
      interval: null,
      number: this.initNumber()
    }
  },
  methods: {
    initNumber() {
      const date = new Date()
      const seconds = date.getSeconds() + date.getMinutes() * 60 // 1-3600
      // 设置伪随机输，根据时间线，算出随机数的和
      // 10-1500
      const randomArr = [1230.36, 1120.61, 1850.68, 106.58, 508.36, 458.51, 1890.61, 623.15]
      let num = 0
      for (let i = 0; i < seconds; i++) {
        num += randomArr[i % randomArr.length]
      }
      return Date.now() / 10000 + num
    },
    startRolling() {
      this.interval = setInterval(() => {
        this.number = this.initNumber();
      }, 2000);
    },
  },
  mounted() {
    this.startRolling()
  },
  beforeDestroy() {
    this.interval && clearInterval(this.interval)
  }
}
</script>

<style scoped lang="less" src="@/assets/css/jackpot.less"></style>