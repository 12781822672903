<template>
    <div>
        <div class="down-text-class">{{ hours + 'H' }} {{ minutes + 'M' }} {{ seconds + 'S' }}</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            hours: '00',
            minutes: '00',
            seconds: '00',
            intervalId: null,
            isInTimeRange: false,
        };
    },
    created() {
        this.updateTime();
        this.intervalId = setInterval(this.updateTime, 1000);
    },
    beforeDestroy() {
        clearInterval(this.intervalId);
    },
    methods: {
        updateTime() {
            const now = new Date();
            // 设置当天的目标时间范围：凌晨1点到下午1点
            const start = new Date();
            start.setHours(1, 0, 0, 0);
            const end = new Date();
            end.setHours(13, 0, 0, 0);
            this.isInTimeRange = now >= start && now < end;
            if (!this.isInTimeRange) {
                // 不在时间范围内，显示为 00:00:00
                this.hours = '00';
                this.minutes = '00';
                this.seconds = '00';
                return;
            }
            const distance = end - now;
            this.hours = this.padTime(Math.floor((distance / (1000 * 60 * 60))));
            this.minutes = this.padTime(Math.floor((distance / (1000 * 60)) % 60));
            this.seconds = this.padTime(Math.floor((distance / 1000) % 60));
        },
        padTime(value) {
            return value.toString().padStart(2, '0');
        }
    }
};
</script>
<style scoped>
.down-text-class {
    width: 160px;
    color: #FAFF00;
    font-size: 18px;
}
</style>