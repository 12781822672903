<template>
  <div v-if="stakingthumbs.length > 0" class="banner-container">
    <!-- :style="{ background: 'linear-gradient(to bottom, ' + $store.state.normalBackgroundColor + ', ' + $store.state.lightBackgroundColor + ', ' + '#0D131C 80%' + ')' }"> -->
    <div class="banners dividends__banners"
      :style="{ background: $store.state.windowWidth < 768 ? $store.state.normalBackgroundColor : '' }">
      <swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs"
        @slideChange="$store.state.windowWidth < 768 ? onSlideChange() : ''" ref="swiperThumbs">
        <swiper-slide v-for="(item, index) in stakingthumbs" :key="index">
          <!-- <router-link to="/news-detail" class="picture__link-box"> -->
          <div @click="toPage(item.url, item.jump_url, item)" class="picture__link-box">
            <!-- <img :src="item.img" @load="$store.state.windowWidth < 768 ? handleImageLoad(index) : ''" /> -->
            <img :src="generateImageURL('f=auto,dpr=2,q=80',item.img)" @load="$store.state.windowWidth < 768 ? handleImageLoad(index) : ''" />
          </div>
          <!-- </router-link> -->
        </swiper-slide>
      </swiper>
      <div class="splide__arrows" v-if="stakingthumbs.length > 0">
        <button class="splide__arrow splide__arrow--prev" aria-controls="splide02-track" aria-label="Previous slide">
          <button type="button" class="button button_sm button_grey1 button_center button_only-icon">
            <svgIcon icon="icon-arrow-left-small" class widthName="18" heightName="18"></svgIcon>
          </button>
        </button>
        <button class="splide__arrow splide__arrow--next" aria-controls="splide02-track" aria-label="Next slide">
          <button type="button" class="button button_sm button_grey1 button_center button_only-icon">
            <svgIcon icon="icon-arrow-right-small" class widthName="18" heightName="18"></svgIcon>
          </button>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { banner_index_api } from "@/api/index";
import svgIcon from "@/components/svg.vue";
export default {
  components: {
    svgIcon
  },
  data() {
    return {
      banner: {},
      swiperOptionThumbs: {
        autoplay: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".splide__arrow--next",
          prevEl: ".splide__arrow--prev",
        },
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 16,
        breakpoints: {
          640: {
            slidesPerView: 1,
            slidesPerGroup: 1,
          },
          1200: {
            slidesPerView: 3,
          },
        },
      },
      currentIndex: 0
    };
  },
  computed: {
    stakingthumbs() {
      // const key = this._isMobile() ? "mobile" : "pc";
      const key = "mobile";
      return [
        ...(this.banner?.[key] || []),
        ...(this.$store.state.system.systemNew?.banner[key] || [])
      ]
        .filter(item => item.lang == this.$helper.getLang())
        .sort((a, b) => b.sort - a.sort);
    }
  },
  methods: {
    // 获取轮播图
    async getBanner() {
      const res = await banner_index_api();
      if (res && res.data.code == 1) {
        this.banner = res.data.data;
      }
    },
    // 页面跳转
    toPage(url, jump_url) {
      if (url) {
        this.platUrl(url)
      } else {
        this.$router.push({ path: jump_url })
      }
    },
    handleImageLoad(index) {
      if (!this.isMobileFlag) return
      if (index === this.currentIndex) {
        this.updateBackgroundColor(this.generateImageURL('f=auto,dpr=2,q=80',this.stakingthumbs[index].img));
      }
    },
    onSlideChange() {
      this.updateBackgroundColor(this.generateImageURL('f=auto,dpr=2,q=80',this.stakingthumbs[this.$refs.swiperThumbs.swiper.activeIndex].img));
    },
    updateBackgroundColor(imageSrc) {
      
      const img = new Image();
      img.crossOrigin = "Anonymous"; // 允许跨域请求
      img.src = imageSrc;
      img.onload = () => {

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const width = img.width;
        const height = img.height;
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        if (ctx) {
          const imageData = ctx.getImageData(0, 0, width, height);
          const data = imageData.data;
          let r = 0, g = 0, b = 0;
          const totalPixels = data.length / 4;
          for (let i = 0; i < totalPixels; i++) {
            r += data[i * 4];       // 红色
            g += data[i * 4 + 1];   // 绿色
            b += data[i * 4 + 2];   // 蓝色
          }
          r = Math.floor(r / totalPixels);
          g = Math.floor(g / totalPixels);
          b = Math.floor(b / totalPixels);
          const normalColor = `rgb(${r}, ${g}, ${b})`;
          const lightColor = this.lightenSlightly(r, g, b, 0.2); // 减弱 20% 的饱和度
          this.$store.commit("changeNormalBackgroundColor", normalColor)
          this.$store.commit("changeLightBackgroundColor", lightColor)

          let topColor = document.getElementById("topBoxStyle");
          topColor.content = this.rgbToHex(r, g, b);
          let tileColor = document.getElementById("TileColorStyle");
          tileColor.content = this.rgbToHex(r, g, b);
        }
      };
      img.onerror = () => {
        console.error("Failed to load image.");
      };
    },
    // 转16进制
    rgbToHex(r, g, b) {
      const toHex = (value) => value.toString(16).padStart(2, '0'); // 转换为 16 进制并补足两位
      return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    },
    lightenSlightly(r, g, b, factor) {
      // factor 取值范围：0 ~ 1, 0 表示没有变化，1 表示完全淡化
      r = Math.floor(r + (255 - r) * factor);
      g = Math.floor(g + (255 - g) * factor);
      b = Math.floor(b + (255 - b) * factor);
      return `rgb(${r}, ${g}, ${b})`;
    }
  },
  mounted() {
    this.getBanner();
  }
};
</script>

<style scoped>
@media (min-width:769px) {
  .banner-container {
    width: 100%;
    max-width: 1088px;
    padding-left: 24px;
    padding-right: 24px;
    margin: 0 auto;
    position: relative;
  }
}

.dividends__banners {
  margin-bottom: 30px;
}

@media (max-width:768px) {
  .dividends__banners {
    /* margin-bottom: var(--padding); */
    margin-bottom: 0;
    padding: 10px 16px 0 16px;
  }

  .splide__arrow--prev {
    left: 16px;
    border-radius: 14px 0 0 14px;

  }

  .splide__arrow--next {
    right: 16px;
    border-radius: 0 14px 14px 0;
  }

  .splide__arrow {
    top: 10px;
    height: calc(100% - 10px);
  }
}

.dividends__banners .picture {
  border-radius: 14px;
  overflow: hidden;
  position: relative;
  height: 0;
  padding-top: 62.5%;
  background-color: #202a39;
  -webkit-transform: translateZ(0);
}

.dividends__banners .picture.double {
  padding-top: 30.5232%;
}

.dividends__banners .picture__inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.dividends__banners .picture__inner:hover img {
  transform: scale(1.025);
}

.dividends__banners img {
  border-radius: 8px;
}

@media (max-width: 640px) {

  .dividends__banners img,
  .dividends__banners .el-image {
    width: 100%;
  }
}

.picture__link-box {
  display: block;
  border-radius: 14px;
  overflow: hidden;
  max-height: 210px;
  position: relative;
  /* padding-bottom: 62.5%; */
  padding-bottom: 46.5%;

}

@media (max-width: 640px) {
  .picture__link-box {
    padding-bottom: 46.5%;
  }
}

.picture__link-box /deep/ img {
  -o-object-fit: cover;
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
  transition: transform 0.3s;
  border-radius: 14px;
  position: absolute;

}

.picture__link-box:hover img {
  transform: scale(1.025);
}

.dividends__banners picture {
  position: relative;
  z-index: 1;
}

.dividends__banners img {
  -o-object-fit: cover;
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
  transition: transform 0.3s;
}

.dividends__banners .button_sm {
  width: 26px;
  height: 26px;
}

.dividends__banners .button_sm svg {
  width: 100%;
  height: 100%;
  display: block;
  transition: fill 0.1s ease-in-out;
}

.banners {
  position: relative;
}

.arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
}

.arrow__prev {
  left: 0;
}

.arrow__next {
  right: 0;
}
</style>
